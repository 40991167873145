<template>
  <div id="app-receiver" class="app-container">
    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <el-menu-item :index="0">全部</el-menu-item>
      <el-menu-item :index="1">未分配</el-menu-item>
      <el-menu-item :index="2">已分配</el-menu-item>
      <el-button type="primary" class="new-btn" @click="addBlue('add')">新建</el-button>
    </el-menu>
    <div class="session1">
      <div class="shopBox" ref="shopBox" @click="treeClick">
        <i>组织：</i>
        <input type="text" readonly name="" id="" :value="checkedName" />
        <span class="el-tree-node__expand-icon el-icon-caret-right expanded"></span>
      </div>
      <div class="treeBox" ref="treeBox" v-show="treeIsShow">
        <el-input placeholder="请输入门店名称搜索" v-model="searchOrg" @change="searchOrg" class="input-with-select">
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
        <el-tree
          node-key="orgcode"
          :data="shopsTree"
          :props="defaultProps"
          ref="tree"
          :default-expanded-keys="[orgcode]"
          :filter-node-method="filterNode"
          :expand-on-click-node="false"
          highlight-current="true"
          @node-click="handleNodeClick"
        >
        </el-tree>
        <div class="dialogBtn pt20 mb20 borderTop">
          <button @click="getOrgcode" type="button" class="ml20 ag-model-btn blue fts mr5">确定</button>
          <button @click="hideAll" type="button" class="ag-model-btn gray fts">取消</button>
        </div>
      </div>
      <el-input
        placeholder="搜索接收器编号、MAC_ID"
        v-model="keyword"
        class="input-with-select"
        @change="getReceiverList"
      >
        <el-button class="searchbtn" slot="append" icon="el-icon-search" @click="getReceiverList"></el-button>
      </el-input>
    </div>
    <div class="integratable">
      <el-table
        :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
        row-key="id"
        @sort-change="sortChange"
        style="width: 100%"
      >
        <el-table-column prop="rno" sortable="custom" show-overflow-tooltip align="right" label="接收器编号">
        </el-table-column>
        <el-table-column prop="macid" sortable="custom" show-overflow-tooltip align="left" label="MAC-ID">
        </el-table-column>
        <el-table-column prop="location" sortable="custom" show-overflow-tooltip align="left" label="位置">
          <template slot-scope="scope">
            <span>{{ localOptions[scope.row.location].label }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cname" sortable="custom" align="left" show-overflow-tooltip label="客户名称">
        </el-table-column>
        <el-table-column prop="shopname" sortable="custom" align="left" show-overflow-tooltip label="门店名称">
        </el-table-column>
        <el-table-column label="操作" align="center" min-width="100">
          <template slot-scope="scope">
            <el-button @click="getReceiverInfo(scope.row)" type="text" size="small">配置</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flexend">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 30, 50]"
        :page-size="100"
        layout="total,sizes, prev, pager, next"
        :total="tableData.length"
      >
      </el-pagination>
    </div>

    <!--设置探针-->
    <el-dialog
      :title="`${type}蓝牙接收器`"
      custom-class="addDialog"
      :visible.sync="dialogFormVisible"
      :before-close="cancelSetBlue"
    >
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="MAC_ID：" :label-width="formLabelWidth" prop="macid">
          <el-input v-model="form.macid" autocomplete="off" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="位置：" :label-width="formLabelWidth" prop="location">
          <el-select v-model="form.location" placeholder="请选择" value-key="value">
            <el-option v-for="item in localOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择客户：" :label-width="formLabelWidth" prop="cname">
          <el-select v-model="form.cname" placeholder="请选择" @change="selectGroup" value-key="cid">
            <el-option v-for="item in CusOptions" :key="item" :label="item.cname" :value="item"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择门店：" :label-width="formLabelWidth" prop="shopname">
          <div class="shopBox fl" ref="shoptree" @click="setTree">
            <input type="text" readonly name="" id="" :value="form.shopname" placeholder="请选择" />
            <span class="el-tree-node__expand-icon el-icon-caret-right expanded"></span>
          </div>
          <div class="treeBox setShops" v-show="shopShow">
            <el-tree
              node-key="shopId"
              :data="shopsData"
              :props="defaultProps"
              :default-expanded-keys="[form.shopid]"
              ref="shop"
              :filter-node-method="shopfilterNode"
              :expand-on-click-node="false"
              highlight-current="true"
              @node-click="shopClick"
            >
              <span slot-scope="{ node, data }">
                <span :class="{ 'tree-disabled': data.disabled }">{{ node.label }}</span>
              </span>
            </el-tree>
            <div class="dialogBtn pt20 mb15 borderTop">
              <button @click="getShopOrgcode" type="button" class="ml20 ag-model-btn blue fts mr5">确定</button>
              <button @click="this.shopShow = false" type="button" class="ag-model-btn gray fts">取消</button>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelSetBlue">取 消</el-button>
        <el-button type="primary" @click="updateReceiver('ruleForm')">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import $ from 'jquery'
import treemixin from 'src/mixins/tree'
import { getReceiverList, getCusList, getReceiverInfo, updateReceiver, addReceiver } from 'src/api/legacy'

var storeHttp = '/newretail'

export default {
  mixins: [treemixin],
  data() {
    return {
      type: '新建',
      shopsData: [],
      currentPage: 1,
      pageSize: 10,
      activeIndex: 0,
      keyword: '',
      tableData: [],
      dialogFormVisible: false,
      form: {
        macid: '',
        location: 0,
        groupid: '',
        cname: '',
        shopid: '',
        shopname: '', //安装门店
      },
      groupcode: '',
      CusOptions: [],
      localOptions: [
        {
          label: '暂不分配',
          value: 0,
        },
        {
          label: '近端',
          value: 1,
        },
        {
          label: '远端',
          value: 2,
        },
      ],
      rules: {
        macid: [
          {
            required: true,
            message: '请填写',
            trigger: 'blur',
          },
        ],
      },
      probeid: '',
      shopShow: false,
      formLabelWidth: '120px',
    }
  },
  mounted() {
    let receiver = JSON.parse(sessionStorage.getItem('receiver'))
    const loading = this.$loading({
      text: '加载中',
      target: document.querySelector('#app-receiver'),
    })
    this.getTreeData()
      .then(() => {
        if (receiver === null) {
          //缓存
          console.log('没有缓存')
        } else {
          this.activeIndex = receiver.active
          this.orgcode = receiver.code !== '' ? receiver.code : this.orgcode
          this.keyword = receiver.keyword
          this.checkedName = receiver.treeName
        }
        this.overviewInit()
        this.$refs.tree.setCurrentKey(this.orgcode)
      })
      .finally(() => {
        loading.close()
      })

    document.addEventListener('click', (e) => {
      if (this.$refs.shoptree && !this.$refs.shoptree.contains(e.target)) {
        this.shopShow = false
      }
    })
    window.onbeforeunload = (e) => {
      //缓存
      let obj = {
        active: this.activeIndex,
        code: this.orgcode,
        keyword: this.keyword,
        treeName: this.checkedName,
      }
      sessionStorage.setItem('receiver', JSON.stringify(obj))
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      //切换menu
      this.activeIndex = key
      this.currentPage = 1
      this.getReceiverList()
    },
    shopfilterNode(val, data) {
      //过滤组织
      if (data.orgType === 2) {
        data.disabled = true
      }
      return data.prmType >= 0 && data.orgcode.indexOf(val) === 0
    },
    checkshopFilter() {
      this.$refs.shop.filter(true)
    },
    shopClick(data) {
      //选中门店
      if (data.disabled) return
      this.getTreeOrgcode = data
    },
    getShopOrgcode() {
      if (this.getTreeOrgcode) {
        this.form.shopid = this.getTreeOrgcode.shopId
        this.form.shopname = this.getTreeOrgcode.name
      }
      this.shopShow = false
    },
    getReceiverList() {
      //列表
      let data = {
        allocation: this.activeIndex,
        parentcode: this.orgcode,
        macid: this.keyword,
      }
      const loading = this.$loading({
        text: '加载中',
        target: document.querySelector('.el-table__body'),
      })
      getReceiverList(data).then((res) => {
        if (res && res.status === 0) {
          this.tableData = res.data
          this.currentPage = 1
          loading.close()
        }
      })
    },
    setTree() {
      this.shopShow = true
      const shopbox = $('.setShops .el-tree')
      const selectNode = $('.el-tree-node.is-current.is-focusable')
      setTimeout(() => {
        if (!selectNode.length) return
        shopbox.scrollTop(0)
        const t = shopbox.offset().top
        const y = selectNode.offset().top
        const interval = y - t + 'px'
        shopbox.animate({
          scrollTop: interval,
        })
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    overviewInit() {
      //页面初始化
      this.getReceiverList()
      this.checkFilter()
      this.getCusList()
      this.shopsData = JSON.parse(JSON.stringify(this.arrData))
    },
    sortChange(column) {
      const { prop } = column
      const order = column.order === 'ascending' ? 1 : -1
      this.tableData.sort((a, b) => {
        let av = isNaN(Number(a[prop])) ? a[prop] : Number(a[prop])
        let bv = isNaN(Number(b[prop])) ? b[prop] : Number(b[prop])
        av = typeof av === 'undefined' ? '' : av
        bv = typeof bv === 'undefined' ? '' : bv
        if (typeof av === 'number' && typeof bv === 'number') {
          return av > bv ? order : -order
        } else if (typeof av === 'string' && typeof bv === 'string') {
          return av.toLowerCase() > bv.toLowerCase() ? order : -order
        } else if (typeof av === 'number' && typeof bv === 'string') {
          return -order
        } else if (typeof av === 'string' && typeof bv === 'number') {
          return order
        }
      })
    },
    getCusList(row) {
      //查询客户列表
      getCusList().then((res) => {
        if (res && res.status === 0) {
          this.CusOptions = res.data
          this.CusOptions.push({ ccode: '-1', cname: '暂不分配', cid: 1 })
        }
      })
    },

    //设置
    getReceiverInfo(row) {
      //查询指定蓝牙
      this.type = '配置'
      this.dialogFormVisible = true
      getReceiverInfo({
        id: row.id,
      }).then((res) => {
        if (res && res.status === 0) {
          this.form = res.data
          this.groupcode = res.data.ccode
          //设置门店树下拉框选中项
          setTimeout(() => {
            this.$refs.shop.filter(this.groupcode)
            this.$refs.shop.setCurrentKey(res.data.shopid)
          })
        }
      })
    },
    selectGroup(value) {
      this.groupcode = value.ccode
      this.form.cid = value.cid
      this.form.shopname = ''
      this.form.shopid = ''
    },
    updateReceiver(formName) {
      // 设置蓝牙
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let api, params
          let { macid, location, shopid } = this.form
          params = { macid, location, shopid }
          if (this.type === '配置') {
            let id = this.form.id
            api = updateReceiver
            Object.assign(params, {
              id,
            })
          } else {
            api = addReceiver
          }
          api(params).then((res) => {
            if (res.data) {
              this.dialogFormVisible = false
              this.$message({
                message: '成功',
                type: 'success',
              })
              this.getReceiverList()
            } else {
              this.$message({
                message: '失败',
                type: 'success',
              })
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    cancelSetBlue() {
      this.dialogFormVisible = false
      this.resetForm('ruleForm')
    },
    addBlue() {
      this.type = '新建'
      this.dialogFormVisible = true
      this.groupcode = ''
      this.form.shopid = ''
      this.$nextTick(() => {
        this.resetForm('ruleForm')
        this.shopsData = JSON.parse(JSON.stringify(this.arrData))
      })
    },
  },
  watch: {
    groupcode(val) {
      //触发过滤方法
      this.$refs.shop.filter(val)
    },
  },
  computed: {
    downloadurl() {
      return (
        storeHttp +
        '/api/probe/downloadprobeuseinfo?probestatus=' +
        this.activeIndex +
        '&orgcode=' +
        this.orgcode +
        '&keyword=' +
        this.keyword
      )
    },
  },
}
</script>

<style lang="less" scoped>
.app-container {
  padding-top: 0px;
}
.shopBox {
  display: inline-block;
}
.new-btn {
  float: right;
  margin-top: 15px;
}
.session1 {
  padding: 20px 20px 0 20px;
  margin: 0 -20px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.el-tree {
  max-height: 330px;
  overflow-y: auto;
}

.input-with-select {
  width: 350px;
}

.flexend {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
}

/* .addDialog .el-input__prefix,
.addDialog .el-input__suffix {
  top: 6px;
} */

.addDialog .el-input {
  width: 200px;
}

.el-input-group {
  width: 350px;
}

.el-input-group__append,
.el-input-group__prepend {
  padding: 0 4px;
}

.addDialog .shopBox span {
  top: 12px;
}

.addDialog .treeBox {
  left: 0;
}

.tree-disabled {
  cursor: not-allowed;
  /* background-color: #F5F5F5; */
  color: #aca899;
}
</style>